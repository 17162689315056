import * as React from "react"
import {  graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faTwitch, faInstagram, faTiktok, faDiscord } from "@fortawesome/free-brands-svg-icons"
import Seo from "../components/seo";

const AboutPage = () => {

  const data = useStaticQuery(query);

  const page = data.strapiAboutPage.data.attributes;

  const social = data.strapiSocialMedia.data.attributes;

  return <Layout>
    <Seo title="About" />
    <div className="container mx-auto grid md:grid-cols-3 py-20 gap-5 md:px-0 px-5 min-h-screen">
      <div className="col-span-3 md:col-span-1">
          {<GatsbyImage image={page.Photo.data.attributes.localFile.childImageSharp.gatsbyImageData} className="w-full"/>}

      </div>
      <div className="col-span-3 md:col-span-2">
        <h1 className="text-white mb-5">{page.Title}</h1>
        <div className="text-gray-300 font-normal mb-10">
          {page.Content}
        </div>

        <div className="flex justify-start">
          
            <a href={social.Twitter} className="text-white mx-5 text-xl"><FontAwesomeIcon icon={faTwitter} className="text-xl"/></a>
            <a href={social.Instagram} className="text-white mx-5 text-xl"><FontAwesomeIcon icon={faInstagram}/></a>
            <a href={social.Twitch} className="text-white mx-5 text-xl"><FontAwesomeIcon icon={faTwitch}/></a>
            <a href={social.TikTok} target="_blank" className="text-white mx-5 text-xl"><FontAwesomeIcon icon={faTiktok}/></a>
            <a href={social.Discord} target="_blank" className="text-white mx-5 text-xl"><FontAwesomeIcon icon={faDiscord}/></a>
         
          </div>
      </div>

     </div>
  </Layout>
};
const query = graphql`
query AboutPage {
  strapiSocialMedia {
    data {
      attributes {
        Facebook
        Instagram
        Twitch
        Twitter
        TikTok
        Discord
      }
    }
  }
  strapiAboutPage {
    data {
      attributes {
        Title
        Content
        Photo {
          data {
            attributes {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 95, width: 1000, height:1000)
                }
              }
            }
          }
        }
      }
    }
  }
}
`;



        

export default AboutPage
